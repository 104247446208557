<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" slot="header" dark color="primary" dense flat>
          <v-btn icon @click.stop="closeModal(modal_name)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text>
            <v-text-field
              :disabled="created"
              class="mt-5"
              :label="$store.getters.translate('name')"
              v-model="record.name">
              <template #label>{{ $store.getters.translate('name') }}<span class="red--text"><strong>*</strong></span></template>
            </v-text-field>
            <base-row
              v-if="token"
              :label="$store.getters.translate('token')"
              :value="token"/>
            <v-alert v-if="token" dense outlined type="success">
              {{ $store.getters.translate("token_created") }}
            </v-alert>
          </v-card-text>
        </div>
        <v-card-footer slot="footer">
        <v-btn v-if="!token" :disabled="!record.name" @click="save" large block>
          {{ $store.getters.translate("create_token") }}
        </v-btn>
      </v-card-footer>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseModal from "../../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../../components/commonComponents/BaseDraggableModal";
import helpFunctions from "../../../plugins/helpFunctions";

export default {
  props: ["modal_name"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("tokens"),
        name: "tokens",
      },
      record: {
        name: null,
      },
      token: null,
      loading: false,
      user_id: null,
      created: false,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      this.token = null;
      this.created = false;
      this.record = {
        name: null,
      };
      this.openModal(this.modal_name);
    },
    save() {
      if(!this.loading) {
        let error = false;
        if (!this.record.name || this.record.name === '') {
          this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate('name'));
          error = true;
        }
        if (!error) {
          this.loading = true;
          this.$http
              .post(this.$store.getters.appUrl + "v2/users/" + this.user_id + "/tokens", this.record)
              .then((response) => {
                this.loading = false;
                this.token = response.data;
                this.created = true;
                this.$emit("refresh");
              })
              .catch(function (error) {
                this.loading = false;
                console.log(error);
              });
        }
      }
    },
  },
};
</script>
