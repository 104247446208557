import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDraggableModal',{ref:_vm.modal_name,attrs:{"name":_vm.modal_name,"max-height":"80vh","width":"70vw","fullscreen":_vm.$vuetify.breakpoint.xsOnly}},[_c(VCard,{attrs:{"loading":_vm.loading,"disabled":_vm.loading}},[_c('BaseModal',[_c(VToolbar,{staticClass:"draggable_selector",attrs:{"slot":"header","dark":"","color":"primary","dense":"","flat":""},slot:"header"},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.closeModal(_vm.modal_name)}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.page.title))]),_c(VSpacer)],1),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c(VDivider),_c(VCardText,[_c(VTextField,{staticClass:"mt-5",attrs:{"disabled":_vm.created,"label":_vm.$store.getters.translate('name')},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$store.getters.translate('name'))),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("*")])])]},proxy:true}]),model:{value:(_vm.record.name),callback:function ($$v) {_vm.$set(_vm.record, "name", $$v)},expression:"record.name"}}),(_vm.token)?_c('base-row',{attrs:{"label":_vm.$store.getters.translate('token'),"value":_vm.token}}):_vm._e(),(_vm.token)?_c(VAlert,{attrs:{"dense":"","outlined":"","type":"success"}},[_vm._v(" "+_vm._s(_vm.$store.getters.translate("token_created"))+" ")]):_vm._e()],1)],1),_c('v-card-footer',{attrs:{"slot":"footer"},slot:"footer"},[(!_vm.token)?_c(VBtn,{attrs:{"disabled":!_vm.record.name,"large":"","block":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$store.getters.translate("create_token"))+" ")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }